import React from "react"
import styled from "styled-components"
import { OneHappyButton } from "../core/OneHappyButton"
import { colors, fontFamily, size, mq } from "../../global.style"
import Fade from "react-reveal/Fade"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"

export const Podcast = () => {
  return (
    <PodcastStyled>
      <Fade left>
        <FlexWrap>
          <StyledImg
            src="https://d1fdloi71mui9q.cloudfront.net/UwismGHST2VCEdBFhR5M_L1mff2d8iZFYyMV2"
            alt="dogsofantwerp"
          />
          <StyledSocialLink
            href="https://www.instagram.com/p/Cauhi8QLi5L/"
            target="_blank"
          >
            <StyledFontAwesomeIcon icon={faInstagramSquare} />
          </StyledSocialLink>

          <Text>
            Featured on{" "}
            <a href="https://www.instagram.com/dogsofantwerpen/">
              @dogsofantwerpen
            </a>{" "}
            podcast
          </Text>
          <OneHappyButtonStyled
            target="_blank"
            href="http://tinyurl.com/3txpfdz6"
            mainColor={"#FFF"}
            textColor={colors.secLight}
          >
            Beluister de podcast!
          </OneHappyButtonStyled>
        </FlexWrap>
      </Fade>
    </PodcastStyled>
  )
}

const PodcastStyled = styled.blockquote`
  background-color: ${colors.secLight};
  margin: 0;
  padding: 1.5rem 1rem;

  ${mq.minWidth(size.portrait)} {
    padding: 2rem;
  }
`

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.minWidth("605px")} {
    flex-direction: row;
    align-items: center;
  }
`

const OneHappyButtonStyled = styled(OneHappyButton)`
  align-self: flex-end;

  ${mq.minWidth("805px")} {
    align-self: center;
  }
`

const Text = styled.p`
  ${fontFamily("OpenSansExtraBoldItalic")};
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${mq.minWidth(size.portrait)} {
    margin-bottom: 0;
    line-height: 3;
    font-size: 1.2rem;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 1.5rem;
  }

  ${mq.minWidth(size.desktop)} {
    font-size: 2.75rem;
  }

  span {
    ${fontFamily("OpenSansItalic")};
    font-size: 1.85rem;
    line-height: 1;

    ${mq.minWidth(size.portrait)} {
      font-size: 3.25rem;
    }

    ${mq.minWidth(size.laptop)} {
      font-size: 5.25rem;
    }

    ${mq.minWidth(size.desktop)} {
      font-size: 6.25rem;
    }
  }
`

const StyledImg = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 3rem;

  display: none;

  ${mq.minWidth("805px")} {
    display: block;
  }
`

const StyledSocialLink = styled.a`
  text-decoration: none;
  color: #fff;
  margin-bottom: 0.5rem;
  transition: opacity 0.7s cubic-bezier(0.16, 1, 0.3, 1);

  ${mq.minWidth("605px")} {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.7;
  }
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 2rem !important;
  margin-right: 0.5rem !important;

  @media screen and (min-width: 740px) {
    margin-right: 1.75rem !important;
  }

  ${mq.minWidth("805px")} {
    font-size: 4rem !important;
  }
`
