import React from "react"
import styled from "styled-components"
import { size, mq, fontFamily } from "../../global.style"
import { getSlug } from "../../pure/String"
import { OneHappyButton } from "../core/OneHappyButton"
import { WysiWyg } from "../core/WysiWyg"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

export const Service = ({ service }) => {
  return (
    <Fade>
      <ServiceStyled>
        <Wrapper>
          <ImgStyled
            fluid={service.image.asset.fluid}
            alt={service.title}
          ></ImgStyled>
        </Wrapper>
        <Figcaption>
          <h2>{service.title}</h2>
          <Description>
            {service._rawShortMessage && (
              <WysiWyg blocks={service._rawShortMessage} />
            )}
          </Description>
          <Bottom>
            {service._rawShortPrices && (
              <Prices>
                <WysiWyg blocks={service._rawShortPrices} />
              </Prices>
            )}
            <OneHappyButtonStyled to={`/formules#${getSlug(service.title)}`}>
              Meer info
            </OneHappyButtonStyled>
          </Bottom>
        </Figcaption>
      </ServiceStyled>
    </Fade>
  )
}

const ServiceStyled = styled.figure`
  background-color: #fff;
  box-shadow: 0 0 0.25rem 0.15rem rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;

  ${mq.minWidth(size.landscape)} {
    margin-bottom: 4rem;
    box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.15);
  }

  ${mq.minWidth(size.portrait)} {
    display: flex;
  }
`

const Wrapper = styled.div`
  ${mq.minWidth(size.portrait)} {
    width: 350px;
    height: 100%;
  }

  ${mq.minWidth("1260px")} {
    width: 450px;
    flex-shrink: 0;
  }
`

const ImgStyled = styled(Img)`
  ${mq.minWidth(size.portrait)} {
    height: 300px;
  }

  ${mq.minWidth("1260px")} {
    height: 450px;
  }
`

const Figcaption = styled.figcaption`
  padding: 1rem;
  width: 100%;
  position: relative;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

  ${mq.minWidth(size.phone)} {
    padding: 1.65rem;
  }

  ${mq.minWidth("1260px")} {
    padding: 3rem;
  }

  h2 {
    ${fontFamily("OpenSansExtraBold")};
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;

    ${mq.minWidth(size.phone)} {
      font-size: 1.5rem;
      margin: 0 0 1rem 0;
      line-height: 0.8;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 2rem 0;
      font-size: 2.5rem;
      line-height: 1.2;
    }
  }

  p {
    ${fontFamily("OpenSansSemiBold")};
    margin: 0 0 1rem 0;
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansExtraBold")};
    }

    ${mq.minWidth("1260px")} {
      margin: 0;
      line-height: 1.5;
      font-size: 1.5rem;
    }
  }
`

const Prices = styled.div`
  flex-grow: 1;
  margin-bottom: 1rem;
  font-size: 0.8rem;

  ${mq.minWidth(size.landscape)} {
    font-size: 1rem;
  }

  ${mq.minWidth("810px")} {
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      ${fontFamily("OpenSansSemiBold")};

      strong {
        ${fontFamily("OpenSansExtraBold")};
      }

      em {
        font-size: 0.75rem;
      }
    }
  }
`

const Bottom = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.minWidth("810px")} {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
`

const Description = styled.div`
  flex-grow: 1;
`

const OneHappyButtonStyled = styled(OneHappyButton)`
  align-self: flex-end;

  ${mq.minWidth("810px")} {
    align-self: center;
  }
`
