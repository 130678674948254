import React from "react"
import styled from "styled-components"
import { size, mq, colors, fontFamily } from "../../global.style"
import { Dog } from "../entity/Dog"
import { Container } from "../core/Container"

export const Dogs = ({ dogs }) => {
  return (
    <DogsStyled>
      <Container>
        <Title>Enkele Packmembers</Title>
        {dogs.nodes &&
          dogs.nodes.map((dog, i) => (
            <Dog key={`dog__${dog.id}`} dog={dog}></Dog>
          ))}
      </Container>
    </DogsStyled>
  )
}

const DogsStyled = styled.div`
  padding: 1.5rem 0;
  background-color: #fff;

  ${mq.minWidth(size.portrait)} {
    padding: 2.5rem 0;
  }

  ${mq.minWidth(size.landscape)} {
    padding: 3rem 0;
  }
`

const Title = styled.h2`
  color: ${colors.text};
  ${fontFamily("OpenSansExtraBold")};
  line-height: 1;
  margin: 0;
  margin-bottom: 2rem;

  ${mq.minWidth(size.laptop)} {
    margin-bottom: 4rem;
    font-size: 2.75rem;
  }
`
