export const getDogsAge = birthday => {
  const now = new Date(Date.now())
  const birthdayDate = new Date(birthday)
  const diffYear = now.getFullYear() - birthdayDate.getFullYear() - 1
  if (diffYear === 0) {
    return "<1"
  } else {
    return diffYear
  }
}
