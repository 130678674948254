import React from "react"
import { Review } from "../entity/Review"
import styled from "styled-components"

export const Reviews = ({ reviews }) => {
  return (
    <ReviewsStyled key="revs">
      {reviews.nodes &&
        reviews.nodes.map(review => (
          <Review key={`review__${review.id}`} review={review} />
        ))}
    </ReviewsStyled>
  )
}

const ReviewsStyled = styled.div`
  background-color: #fff;

  > *:nth-child(even) {
    background-color: #f2f2f2;
  }
`
