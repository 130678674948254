import React from "react"
import styled from "styled-components"
import { fontFamily, size, mq } from "../../global.style"
import { useStaticQuery, graphql } from "gatsby"

export const Contact = () => {
  const contact = useStaticQuery(graphql`
    query Contact {
      sanityFooter {
        name
        address
        email
        tav
        tel
        location {
          lat
          lng
        }
      }
    }
  `)

  return (
    <ContactStyled>
      <Info>
        <div>
          <p>
            <strong>{contact.sanityFooter.name}</strong>
          </p>
          <p>
            Email:{" "}
            <StyledLink href={`mailto:${contact.sanityFooter.email}`}>
              {contact.sanityFooter.email}
            </StyledLink>
          </p>
        </div>
      </Info>
    </ContactStyled>
  )
}

const ContactStyled = styled.div`
  background-color: #2b2b2b;
  padding: 1rem 0;

  ${mq.minWidth("700px")} {
    display: flex;
    justify-content: space-between;
  }

  ${mq.minWidth(size.laptop)} {
    padding: 1rem 0;
  }

  ${mq.minWidth(size.laptop)} {
    padding: 2.5rem 0;
  }
`

const Info = styled.aside`
  color: #fff;
  margin: 1rem;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  display: none;

  ${mq.minWidth(size.mobile)} {
    display: block;
  }

  ${mq.minWidth("700px")} {
    margin-bottom: 0;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 2rem;
    font-size: 1.1rem;
  }

  ${mq.minWidth(size.landscape)} {
    font-size: 1.3rem;
  }

  ${mq.minWidth("1300px")} {
    margin: 2.5rem 5rem;
    font-size: 1.75rem;
  }

  ${mq.minWidth(size.desktop)} {
    font-size: 2rem;
  }

  p {
    margin: 0;

    ${fontFamily("OpenSansSemiBold")};

    strong,
    a {
      ${fontFamily("OpenSansBold")};
    }
  }
`
// const Map = styled.div`
//   margin: 1rem;

//   ${mq.minWidth("700px")} {
//     margin: 2rem;
//   }

//   ${mq.minWidth(size.landscape)} {
//     margin: 2.5rem 5rem;
//   }

//   img {
//     max-width: 100%;
//     display: block;
//   }
// `

const StyledLink = styled.a`
  color: #f2f2f2;
  text-decoration: none;
  ${fontFamily("OpenSansSemiBold")};
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`
