import React from "react"
import styled from "styled-components"
import { size, mq, colors, fontFamily } from "../../global.style"
import Img from "gatsby-image"
import { getDogsAge } from "../../pure/Date"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faPaw } from "@fortawesome/pro-solid-svg-icons"

export const Dog = ({ dog, reverse = false }) => {
  return (
    <DogStyled>
      <Wrapper>
        <LineWrap>
          <Img fluid={dog.image.asset.fluid}></Img>
        </LineWrap>
      </Wrapper>
      <Content>
        <Title>
          <span>{dog.name}</span>
          {dog.birthDate && (
            <Trail>
              {Array(getDogsAge(dog.birthDate))
                .fill()
                .map((x, i) => (
                  <FontAwesomeIcon
                    key={`key_${i}`}
                    size="xs"
                    icon={faPaw}
                    rotation={90}
                  />
                ))}
            </Trail>
          )}
        </Title>
        <Info>
          <Breed>{dog.breed}</Breed>{" "}
          {dog.birthDate && (
            <>
              - <Age>{getDogsAge(dog.birthDate)} Jaar</Age>
            </>
          )}
        </Info>
        <Message>{/* <WysiWyg blocks={dog._rawMessage} /> */}</Message>
        {dog.insta && (
          <Follow
            href={`https://www.instagram.com/${dog.insta.substring(1)}`}
            target="_blank"
          >
            <FontAwesomeIconStyled icon={faInstagramSquare} />
            <span>
              Volg deze hond <em>op Instagram</em>
            </span>
          </Follow>
        )}
      </Content>
    </DogStyled>
  )
}

const DogStyled = styled.div`
  margin: 0.75rem 0;
  display: flex;
  align-items: center;
  flex-direction: row;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${mq.minWidth(size.portrait)} {
    margin: 1rem 0;
  }

  ${mq.minWidth(size.laptop)} {
    margin: 2.5rem 0;
  }
`

const Wrapper = styled.div`
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;

  ${mq.minWidth(size.portrait)} {
    width: 140px;
    height: 140px;
  }

  ${mq.minWidth(size.laptop)} {
    width: 200px;
    height: 200px;
  }
`

const LineWrap = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 93px;
  height: 93px;

  ${mq.minWidth(size.portrait)} {
    width: 130px;
    height: 130px;
  }

  ${mq.minWidth(size.laptop)} {
    width: 190px;
    height: 190px;
  }
`

const Info = styled.p`
  ${fontFamily("OpenSansExtraBoldItalic")};
  margin: 0;
  font-size: 0.8rem;

  ${mq.minWidth(size.portrait)} {
    font-size: 1rem;
    text-align: left;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 1.5rem;
  }
`

const Follow = styled.a`
  ${fontFamily("OpenSansExtraBoldItalic")};
  margin: 0;
  text-decoration: none;
  color: ${colors.sec};
  display: block;
  transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.875rem;

  em {
    font-style: normal;
    display: none;

    ${mq.minWidth(size.phone)} {
      display: inline;
    }
  }

  ${mq.minWidth(size.portrait)} {
    justify-content: flex-start;
    flex-direction: row;
  }

  svg {
    transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    color: ${colors.black};

    svg {
      color: ${colors.black};
    }
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 1rem;
  }
`

const Breed = styled.span``
const Age = styled.span``

const Content = styled.div`
  width: 100%;
  margin: 0 0 0 1rem;

  ${mq.minWidth(size.portrait)} {
    margin: 0 0 0 2rem;
    width: initial;
  }

  ${mq.minWidth(size.laptop)} {
    margin: 0 3rem 0;
  }
`

const Title = styled.h3`
  color: ${colors.text};
  ${fontFamily("OpenSansExtraBold")};
  line-height: 1;
  margin: 0;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;

    ${mq.minWidth(size.portrait)} {
      margin-right: 0.5rem;
    }
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 2.25rem;
  }
`

const Message = styled.div`
  margin: 0;
  color: ${colors.text};
  ${fontFamily("OpenSansItalic")};

  ${mq.minWidth(size.portrait)} {
    text-align: left;
  }

  > * :last-child {
    margin-bottom: 0.75rem;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 1.4rem;
  }

  strong {
    ${fontFamily("OpenSansSemiBoldItalic")};
  }
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 1.75rem !important;
  color: ${colors.sec};
  margin-right: 0.5rem;

  ${mq.minWidth(size.portrait)} {
    margin-right: 0.5rem;
  }
`

const Trail = styled.span`
  font-size: 1.25rem;
  position: relative;
  bottom: -0.25rem;

  ${mq.minWidth(size.portrait)} {
    display: inline;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 1.75rem;
  }

  > * {
    margin: 0 0.025rem;

    ${mq.minWidth(size.laptop)} {
      margin: 0 0.125rem;
    }

    &:nth-child(even) {
      position: relative;
      bottom: 0.5rem;
    }
  }
`
