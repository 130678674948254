import React from "react"
import styled from "styled-components"
import { Container } from "../core/Container"
import { size, mq } from "../../global.style"
import { Service } from "../entity/Service"

export const Services = ({ services }) => {
  return (
    <ServicesStyled>
      <Container>
        {services.nodes &&
          services.nodes.map(service => (
            <Service key={`Service__${service.id}`} service={service}></Service>
          ))}
      </Container>
    </ServicesStyled>
  )
}

const ServicesStyled = styled.div`
  padding: 1rem 0;
  background-color: #fff;

  ${mq.minWidth(size.landscape)} {
    padding: 5rem 0;
  }
`
