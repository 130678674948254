import React from "react"
import styled from "styled-components"
import { colors, fontFamily, size, mq } from "../../global.style"
import { Container } from "../core/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-solid-svg-icons"
import { faStar as faStarOpen } from "@fortawesome/pro-regular-svg-icons"
import { WysiWyg } from "../core/WysiWyg"
import Fade from "react-reveal/Fade"
export const Review = ({ review }) => {
  return (
    <ReviewStyled>
      <Container>
        <Fade>
          <Title>{review.name}</Title>
          <Stars>
            {Array.from(Array(review.stars)).map((x, i) => (
              <FontAwesomeIcon icon={faStar} key={`key_${i}`} />
            ))}
            {Array.from(Array(5 - review.stars)).map((x, i) => (
              <FontAwesomeIcon icon={faStarOpen} key={`key_open_${i}`} />
            ))}
          </Stars>
          <Message>
            <WysiWyg blocks={review._rawMessage} />
          </Message>
        </Fade>
      </Container>
    </ReviewStyled>
  )
}

const ReviewStyled = styled.div`
  padding: 1.5rem 0;

  ${mq.minWidth(size.laptop)} {
    padding: 10rem 0;
  }
`

const Title = styled.h2`
  color: ${colors.text};
  ${fontFamily("OpenSansExtraBold")};
  line-height: 1;
  margin: 0;
  margin-bottom: 0.75rem;

  ${mq.minWidth(size.laptop)} {
    margin-bottom: 1rem;
    font-size: 2.75rem;
  }
`

const Stars = styled.div`
  color: ${colors.text};
  margin-bottom: 1rem;

  ${mq.minWidth(size.laptop)} {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

const Message = styled.div`
  margin: 0;
  color: ${colors.text};
  ${fontFamily("OpenSansItalic")};
  line-height: 1.6;

  ${mq.minWidth(size.laptop)} {
    font-size: 1.5rem;
  }

  strong {
    ${fontFamily("OpenSansSemiBoldItalic")};
  }
`
