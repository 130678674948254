import React from "react"
import Layout from "../Layout"
import { Hero } from "../components/rings/Hero"
import { Services } from "../components/rings/Services"
import { Quote } from "../components/rings/Quote"
import { Reviews } from "../components/rings/Reviews"
import { Dogs } from "../components/rings/Dogs"
import { ContactForm } from "../components/rings/ContactForm"
import { Contact } from "../components/rings/Contact"
import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../components/Seo"
import { Podcast } from "../components/rings/Podcast"
import { VacancyQuote } from "../components/rings/VacancyQuote"

export default function Home() {
  const homeData = useStaticQuery(graphql`
    query HomeDogsAndReviews {
      sanityHero(id: { eq: "-8ac01c66-1a13-5f8f-967f-e5c0ef7a3530" }) {
        id
        image {
          asset {
            fluid(maxWidth: 3000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawQuote
      }

      allSanityReview(filter: { isOnHome: { eq: true } }) {
        nodes {
          id
          name
          stars
          _rawMessage
        }
      }

      allSanityFormula(
        sort: { fields: _createdAt }
        filter: { isOnHome: { eq: true } }
      ) {
        nodes {
          id
          title
          _rawShortMessage
          _rawShortPrices
          image {
            asset {
              fluid(maxWidth: 430) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }

      allSanityDog(filter: { isOnHome: { eq: true } }) {
        nodes {
          id
          name
          birthDate
          _rawMessage
          breed
          insta
          image {
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo urlLoc="https://www.onehappyhound.be/" />
      {homeData && homeData.sanityHero && <Hero hero={homeData.sanityHero} />}
      <VacancyQuote />
      {homeData &&
        homeData.allSanityFormula &&
        homeData.allSanityFormula.nodes.length !== 0 && (
          <Services services={homeData.allSanityFormula} />
        )}
      <Podcast />
      <Quote />
      {homeData &&
        homeData.allSanityReview &&
        homeData.allSanityReview.nodes.length !== 0 && (
          <Reviews reviews={homeData.allSanityReview} />
        )}
      {homeData &&
        homeData.allSanityDog &&
        homeData.allSanityDog.nodes.length !== 0 && (
          <Dogs dogs={homeData.allSanityDog} />
        )}
      <ContactForm />
      <Contact />
    </Layout>
  )
}
