import React from "react"
import styled from "styled-components"
import { fontFamily, size, mq, colors } from "../../global.style"
export const ContactForm = () => {
  return (
    <ContactFromStyled>
      <FormStyled
        name="GeneralContactForm"
        method="POST"
        data-netlify="true"
        action="/thank-you"
      >
        <input type="hidden" name="form-name" value="GeneralContactForm" />
        <p>
          <label htmlFor="first_name">
            Hallo <strong>OneHappyHound</strong>, mijn naam is{" "}
          </label>
          <input
            id="first_name"
            placeholder="jouw naam"
            type="text"
            name="first_name"
            required
          />
          ,
        </p>
        <p>
          <label htmlFor="pet_name">mijn huisdier heet </label>
          <input
            id="pet_name"
            type="text"
            placeholder="huisdier naam"
            name="pet_name"
            required
          />
          .
        </p>
        <p>
          <label htmlFor="gsm">Bel me op het gsm nummer </label>
          <input id="gsm" type="tel" placeholder="jouw gsm nummer" name="gsm" />
          ,
        </p>
        <p>
          <label htmlFor="email">of stuur me een email op </label>
          <input
            id="email"
            type="email"
            placeholder="jouw@email.com"
            name="email"
            required
          />
          .
        </p>
        <button type="submit">Neem contact op</button>
      </FormStyled>

      <FootNote>
        One happy Hound neemt vandaag nog contact op met jou om je te helpen met
        je huisdier zijn noden. <br /> Voor zeer dringende zaken kun je ons
        altijd <a href="tel:+32489295045">opbellen</a>.
      </FootNote>
    </ContactFromStyled>
  )
}
const ContactFromStyled = styled.div`
  background-color: #f2f2f2;
  padding: 2rem;

  ${mq.minWidth(size.portrait)} {
    padding: 4rem 2rem 3rem;
  }

  ${mq.minWidth(size.landscape)} {
    padding: 6rem 2rem 5.5rem;
  }

  ${mq.minWidth(size.laptop)} {
    padding: 10rem 2rem 7.5rem;
  }
`

const FormStyled = styled.form`
  text-align: center;
  ${fontFamily("OpenSansBold")};
  font-size: 1.25rem;

  ${mq.minWidth(size.portrait)} {
    font-size: 1.5rem;
  }

  ${mq.minWidth(size.landscape)} {
    font-size: 2rem;
  }

  ${mq.minWidth(size.laptop)} {
    font-size: 3rem;
  }

  button {
    text-decoration: none;
    appearance: none;
    border: 0;
    background-color: ${colors.sec};
    color: #fff;
    ${fontFamily("OpenSansExtraBoldItalic")};
    font-size: 1rem;
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);
    cursor: pointer;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &:hover {
      transform: scale(1.05);
    }

    ${mq.minWidth(size.portrait)} {
      margin-bottom: 2rem;
      font-size: 1.375rem;
      border-radius: 2rem;
      padding: 0.5rem 1.5rem 0.6rem;
    }

    ${mq.minWidth(size.laptop)} {
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }

  p {
    margin: 0;
    margin-bottom: 0.5rem;
    line-height: 1.8;

    ${mq.minWidth(size.portrait)} {
      margin-bottom: 0.5rem;
      line-height: 1.6;
    }

    ${mq.minWidth(size.landscape)} {
      margin-bottom: 1rem;
    }

    strong {
      ${fontFamily("OpenSansExtraBold")};
    }
  }

  input {
    appearance: none;
    border: none;
    ${fontFamily("OpenSansItalic")};
    padding: 0.5rem;
    margin: 0 0.5rem;
    text-indent: 0.5rem;
    font-size: 1rem;
    color: #313131;

    ${mq.minWidth(size.portrait)} {
      font-size: 1.25rem;
    }

    ${mq.minWidth(size.landscape)} {
      font-size: 1.5rem;
      text-indent: 1rem;
    }

    ${mq.minWidth(size.laptop)} {
      font-size: 2.5rem;
    }

    ::placeholder {
      color: #919191;
    }
  }
`

const FootNote = styled.p`
  text-align: center;
  ${fontFamily("OpenSansBoldItalic")};
  line-height: 1.6;
  margin: 0;

  ${mq.minWidth(size.portrait)} {
    font-size: 0.9rem;
  }

  ${mq.minWidth(size.landscape)} {
    font-size: 1.375rem;
  }

  a {
    ${fontFamily("OpenSansExtraBoldItalic")};
    color: #000;
  }
`
