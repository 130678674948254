import React from "react"
import styled from "styled-components"
import { Container } from "../core/Container"
import { WysiWyg } from "../core/WysiWyg"
import { colors, mq, size, fontFamily } from "../../global.style"
import Img from "gatsby-image"
export const Hero = ({ hero }) => {
  return (
    <StyledHero>
      <StyledHeroImage fluid={hero.image.asset.fluid} alt={hero.title} />
      <Container>
        <MainHeader>
          {hero._rawQuote && <WysiWyg blocks={hero._rawQuote} />}
        </MainHeader>
      </Container>
    </StyledHero>
  )
}

const StyledHero = styled.div`
  position: relative;
  height: 28rem;
  overflow: hidden;

  ${mq.minWidth(size.portrait)} {
    height: 50.25rem;
  }

  ${mq.minWidth(size.desktop)} {
    height: 60rem;
  }

  ${mq.minWidth(size.television)} {
    height: 65rem;
  }

  ${mq.minWidth(size.projector)} {
    height: 75rem;
  }
`

const StyledHeroImage = styled(Img)`
  top: 0;
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  @media (hover: hover) {
    position: fixed !important;
    z-index: -1;
  }

  ${mq.minWidth(size.desktop)} {
    height: auto;
  }
`

const MainHeader = styled.h1`
  position: relative;
  ${fontFamily("OpenSansBoldItalic")};
  margin: 0;
  top: 1rem;
  font-size: 1.5rem;
  line-height: 1.3;
  margin: 0 1rem;

  strong {
    ${fontFamily("OpenSansExtraBoldItalic")};
  }

  a {
    ${fontFamily("OpenSansExtraBoldItalic")};
    color: ${colors.sec};

    ${mq.hover("hover")} {
      ${mq.minWidth(size.desktop)} {
        text-decoration: none;
        position: relative;

        &:before {
          content: "";
          transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
          transform-origin: left center;
          left: 0;
          transform: scaleX(0);
          bottom: 10px;
          width: 100%;
          height: 5px;
          background-color: ${colors.sec};
          position: absolute;
        }

        &:hover {
          &:before {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  ${mq.minWidth(size.portrait)} {
    top: 4rem;
    font-size: 2.5rem;
    line-height: 1.3;
    margin: 0 5rem;
  }

  ${mq.minWidth(size.desktop)} {
    top: 7rem;
    font-size: 3.375rem;
    line-height: 1.3;
    margin: 0;
  }
`
